import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { pipe } from 'rxjs';
import { PrecioPipe } from './pipes/precio.pipe';
import { LogoComponent } from './shared/components/logo/logo.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink ,PrecioPipe, LogoComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SIMAppDownload';
  production = environment.production;
}
