import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
    },
    {
        path: 'home',
        loadComponent: () => import("./pages/home/home.component"),
        title: 'Inicio'
    },    
    {
        path: 'pyp',
        loadComponent: () => import("./pages/pyp/pyp.component"),
        title: 'Visita PYP'
    },    
    {
        path: 'carnet',
        loadComponent: () => import("./pages/carnet/carnet.component"),
        title: 'Carnet'
    },
    {
        path: 'historia',
        loadComponent: () => import("./pages/historia/historia.component"),
        title: 'Historia'
    },
    {
        path:"ahorro",
        loadComponent: ()=> import("./pages/ahorro/ahorro.component"),
        title: 'Ahorro'
      },
      {
        path:"orden",
        loadComponent: () => import("./pages/orden/orden.component"),
        title: 'Orden'
      }
];
