<div *ngIf="!production">
    <a href="/carnet?id=61f7d8e6a2640d17a89eb94d" class="me-2">Carnet</a>
    <a href="/pyp?id=6214a0fc4afffe2db0ebf80e" class="me-2">Pyp</a>
    <a href="http://localhost:4200" class="me-2">Inicio</a>
    <a href="/ahorro?id=61f7370e311b61222424d7f8&desde=2020-10-01T00:00:00.000Z&hasta=2023-10-16T23:59:59.000Z"
        class="me-2">Ahorro</a>
    <a href="/historia?id=61f7d972a2640d17a89eb94e" class="me-2">Historia</a>
    <a href="/orden?id=642c30f8fefd4c061498d2fc" class=" me-2">Orden medica</a>
</div>
<router-outlet></router-outlet>
<app-logo></app-logo>